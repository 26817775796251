<template>
  <b-card>
    <div
      v-if="Object.entries(groupedScreenshots).length === 0 && !processing"
      class="text-center"
    >
      <h4>😕 {{ $t('No Data Found') }}</h4>
    </div>
    <b-overlay :show="processing">
      <app-timeline>
        <app-timeline-item
          v-for="(screens, date, groupIndex) in groupedScreenshots"
          :key="date"
          :variant="groupIndex % 2 === 0 ? 'primary' : 'success'"
        >
          <div
            class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
          >
            <h6 v-if="type == 'day'">
              {{ FORMAT_DATE(date, "h a") }}
            </h6>
            <h6 v-else>
              {{ FORMAT_DATE(date, "MMM Do YYYY") }}
            </h6>
            <small class="text-muted">{{
              FORMAT_DATE(date, "MMM Do YYYY")
            }}</small>
          </div>
          <b-row
            v-for="(screenWithMemo, memo, memoIndex) of GROUP_BY(
              screens,
              'memo'
            )"
            :key="memoIndex"
          >
            <b-col
              v-if="memo"
              cols="12"
            >
              <p>{{ memo }}</p>
            </b-col>
            <b-col
              v-for="(value, index) in screenWithMemo"
              :key="index"
              sm="3"
              class="screen-casts"
            >
              <span v-if="userId === self.uuid">
                <b-button
                  v-if="value.endedAt !== value.startedAt"
                  v-b-tooltip.hover="$t('Update Memo')"
                  variant="primary"
                  class="btn-icon memoIcon"
                  size="sm"
                  @click="postMemo(value)"
                >
                  <feather-icon icon="ClipboardIcon" />
                </b-button>
                <b-button
                  v-if="value.endedAt !== value.startedAt"
                  v-b-tooltip.hover="$t('Delete Screencast')"
                  variant="danger"
                  class="btn-icon trashIcon"
                  size="sm"
                  @click="confirmDelete(value)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </span>
              <card-image
                v-slot="{ totalMonitor }"
                :src="value.record.title"
                :height="300"
                @showPreview="showImages"
              >
                <template>
                  <h6 class="mb-1">
                    <div class="d-flex justify-content-between">
                      <div>
                        <feather-icon
                          icon="ClockIcon"
                          size="13"
                        />
                        {{ timeWithOutTimeZone(value.endedAt, "hh:mm A") }}
                      </div>
                      <div>
                        <feather-icon
                          icon="AirplayIcon"
                          size="13"
                        />
                        {{ totalMonitor }}
                      </div>
                    </div>
                  </h6>
                  <b-row>
                    <b-col>
                      <b-progress
                        :value="value.keyClicks"
                        max="100"
                        show-value
                        variant="success"
                        class="progress-bar-success"
                      />
                      <feather-icon icon="TypeIcon" />
                    </b-col>
                    <b-col><b-progress
                      :value="value.mouseMoves"
                      max="100"
                      show-value
                      variant="success"
                      class="progress-bar-success"
                    /><feather-icon
                      icon="MousePointerIcon"
                      class="float-right"
                    /></b-col>
                  </b-row>
                </template>
              </card-image>
            </b-col>
          </b-row>
        </app-timeline-item>
      </app-timeline>
    </b-overlay>
    <div
      v-if="hasMoreActivityPages"
      class="d-flex justify-content-center pb-2"
    >
      <b-button
        variant="outline-primary"
        @click="getUserActivities(activityPageNumber + 1)"
      >
        {{ $t('actions.load-more') }}
      </b-button>
    </div>
    <portal to="lightbox">
      <CoolLightBox
        :items="sources"
        :index="slide"
        @close="slide = null"
      />
    </portal>
    <confirm-delete
      :visible="showDelete"
      :delete-params="deleteParams"
      :processing="deleting"
      @close="
        showDelete = false;
        deleteParams = {};
      "
      @onConfirm="deleteScreenshot"
    />
  </b-card>
</template>
<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BProgress, BCard, BButton, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import CardImage from '@/views/common/components/CardImage.vue'
import useApollo from '@/plugins/graphql/useApollo'
import CoolLightBox from 'vue-cool-lightbox'
import { getUserData } from '@/auth/utils'

import ConfirmDelete from '@/views/common/components/ConfirmModal.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BProgress,
    BCard,
    CardImage,
    CoolLightBox,
    BButton,
    ConfirmDelete,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dateRange: {
      type: Object,
      required: true,
    },
    userId: {
      type: [Object, String],
      default: '',
    },
    timeZone: {
      type: [String],
      default: '',
    },
    type: {
      type: String,
      default: 'day',
    },
  },
  data() {
    return {
      self: getUserData(),
      processing: false,
      screenCasts: [],
      toggler: false,
      slide: null,
      showDelete: false,
      deleteParams: {},
      deleting: false,
      hasMoreActivityPages: false,
      sources: [],
    }
  },
  computed: {
    groupedScreenshots() {
      const screenCasts = this.screenCasts.map(screen => ({
        ...screen,
        timeKey: this.FORMAT_DATE(screen.startedAt, this.groupKey),
      }))
      return this.GROUP_BY(screenCasts, 'timeKey')
    },
    groupKey() {
      if (this.type === 'day') {
        return 'YYYY-MM-DD HH'
      }
      return 'YYYY-MM-DD'
    },
  },
  watch: {
    dateRange() {
      this.getUserActivities()
    },
    userId() {
      this.getUserActivities()
    },
    timeZone() {
      this.getUserActivities()
    },
  },
  created() {
    this.getUserActivities()
  },
  methods: {
    getUserActivities(page = 1) {
      if (this.self.uuid !== this.userId && this.$store.state.project.userRole === 'Member') {
        this.screenCasts = []
        this.hasMoreActivityPages = false
        return
      }

      this.processing = true
      useApollo.activities
        .getUserActivities({
          startTime: this.dateRange.start,
          endTime: this.dateRange.end,
          keyword: this.$store.state.project.selectedProject,
          userId: this.userId,
          first: 20,
          page,
        })
        .then(response => {
          if (page === 1) {
            this.screenCasts = []
          }
          let requiredScreenShots = []
          const screenCastsData = response.data.user
            ? response.data.user
            : response.data.me
          this.activityPageNumber = screenCastsData.activityRecords.currentPage
          this.hasMoreActivityPages = screenCastsData.activityRecords.hasMorePages
          if (response.data.user) {
            requiredScreenShots = screenCastsData.activityRecords.data
          } else {
            const screenCasts = screenCastsData.activityRecords.data
            requiredScreenShots = screenCasts
          }
          this.screenCasts = [...this.screenCasts, ...requiredScreenShots]
          this.setImage()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.processing = false
        })
    },
    setImage() {
      this.toggler = !this.toggler
      const imagesArray = []
      this.screenCasts.forEach(screen => {
        const sources = screen.record.title.split(',')
        sources.forEach(src => {
          const memo = screen.memo ? `<span class="text-white">${screen.memo}</span>` : '&nbsp;'
          imagesArray.push({
            title: `
            <div class="text-white">
               <b>${this.FORMAT_DATE(screen.endedAt, this.COMMON.DATE_TIME_FORMAT)}</b>
            </div>
            <div>${memo}</div>`,
            description: `
              <div class="d-flex justify-content-center">
                <div class="mr-2">
                  <div><i class="fa  fa-keyboard-o"></i> Keyboard Strokes</div>
                  <div>
                    <b>${screen.keyClicks}</b>
                  </div>
                </div>
                 <div class="mr-2">
                  <div><i class="fa  fa-clock-o"></i> Duration</div>
                  <div>
                    <b>${this.CONVERT_HM(screen.duration)}</b>
                  </div>
                </div>
                <div>
                 <div><i class="fa   fa-mouse-pointer"></i> Mouse Clicks</div>
                  <div>
                    <b>${screen.mouseMoves}</b>
                  </div>
                </div>
              </div>
            `,
            src,
          })
        })
      })
      this.sources = imagesArray
    },
    showImages(viewingImage) {
      this.slide = this.sources.findIndex(img => img.src === viewingImage)
    },
    confirmDelete(item) {
      this.showDelete = true
      this.deleteParams = {
        title: 'Delete Screencasts',
        uuid: item.record.uuid,
        body: 'Are you sure you want to delete Screen casts ?',
      }
    },
    deleteScreenshot() {
      this.deleting = true
      useApollo.activities
        .destroyActivity({
          projectUid: this.$store.state.project.selectedProject,
          screencastUid: this.deleteParams.uuid,
        })
        .then(response => {
          this.showDelete = false
          this.deleteParams = {}
          this.getUserActivities()
          this.showSuccess(response.data.deleteScreencastActivity.message)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.deleting = false
        })
    },
    postMemo(value) {
      const self = this
      this.$swal({
        title: 'Post a memo',
        input: 'textarea',
        inputValue: value.memo,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: memo => {
          if (!memo) return null
          return useApollo.activities.postMemo({
            projectUid: self.$store.state.project.selectedProject,
            screencastUid: value.record.uuid,
            memo,
          }).then(response => {
            // eslint-disable-next-line no-param-reassign
            value.memo = memo
            self.$forceUpdate()
            return response
          }).catch(error => {
            self.$swal.showValidationMessage(`Request failed:  ${error}`)
          })
        },
      }).then(result => {
        if (result.value) {
          this.$swal({
            title: result.value.data.updateScreencastMemo.message,
            imageUrl: result.value.avatar_url,
            customClass: { confirmButton: 'btn btn-primary' },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
.trashIcon {
  z-index: 2;
  position: absolute;
  right: 0;
  margin-right: 20px;
  margin-top: 10px;
  display: none;
  color: red;
}
.memoIcon {
  z-index: 2;
  position: absolute;
  right: 0;
  margin-right: 72px;
  margin-top: 10px;
  display: none;
  color: blue;
}
.screen-casts:hover {
  .trashIcon {
    display: block;
  }
  .memoIcon {
    display: block;
  }
}
// .test {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background-color: black;
//   z-index: 99999;
// }
</style>
